
export interface Country {
    name: string,
    code: string,
}

export const COUNTRIES: Country[] = [
    {name: "Germany", code: "DE"},
    {name: "Hungary", code: "HU"},
    {name: "Switzerland", code: "CH"},
    {name: "Scotland", code: "GB-SCT"},

    {name: "Spain", code: "ES"},
    {name: "Albania", code: "AL"},
    {name: "Italzy", code: "IT"},
    {name: "Croatia", code: "HR"},

    {name: "Slovenia", code: "SI"},
    {name: "Denmark", code: "DK"},
    {name: "Serbia", code: "RS"},
    {name: "England", code: "GB"},

    {name: "Netherlands", code: "NE"},
    {name: "France", code: "FR"},
    {name: "Poland", code: "PL"},
    {name: "Austria", code: "AT"},

    {name: "Romania", code: "RO"},
    {name: "Slovakia", code: "SK"},
    {name: "Belgium", code: "BE"},
    {name: "Ukraine", code: "UA"},

    {name: "Portugal", code: "PT"},
    {name: "Czechia", code: "CZ"},
    {name: "Turkey", code: "TR"},
    {name: "Georgia", code: "GE"},
]

export function GetNameByCode(countryCode: string): string{
    const country = COUNTRIES.find((country: Country) => country.code === countryCode.toUpperCase());
    return country ? country.name : ""
}

export function GetCodeByName(countryName: string): string{
    const country = COUNTRIES.find((country: Country) => country.name.toUpperCase() === countryName.toUpperCase());
    return country ? country.code : ""
    return ""
}