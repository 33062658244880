import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppProvider from './Base/ContentProvider';
import AppRouter from './AppRouter';

function App() {
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}

export default App;
