import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { useAppContext } from "./ContentProvider";

function Login() {

    const { login } = useAppContext();

    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let result = await login(data.get('email')?.toString() || "", data.get('password')?.toString() || "");
        if(result) navigate("/home");
    }

    const [showPassword, setShowPassword] = useState(false);

    return (
        <Paper sx={{ width: 300, margin: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }} component="form" onSubmit={handleSubmit}>
                    <Typography
                        component="h1" variant="h5"
                    >Sign IN</Typography>
                    <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email address"
                        name="email"
                    />
                    <TextField
                        required
                        fullWidth
                        type="password"
                        id="password"
                        label="Password"
                        name="password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"

                    >Sign in</Button>
                </Box>
            </Box>
        </Paper>
    )

}

export default Login