import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../config/firebase';
import { Match } from '../DB/DBModels';
import { GetMatches, IsUserAdmin } from '../DB/DatabaseHelper';

//content provider interface
interface ContextProps {
    user: any,
    isUserAdmin: boolean,
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => Promise<boolean>;
    matches: Match[],
    getMatches: () => Match[],
}


const AppContext = createContext<ContextProps | undefined>(undefined)

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within a AppProvider");
    }
    return context;
}

interface AppProviderProps {
    children: ReactNode,
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {

    // FIREBASE USER
    const [user, setUser] = useState<any>(() => {
        const storedValue = localStorage.getItem("user");
        console.log(storedValue)
        return storedValue !== null ? JSON.parse(storedValue) : null;
    })

    const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);


    async function getRights(){
        const isadmin = await IsUserAdmin(auth.currentUser?.uid);

        setIsUserAdmin(isadmin);
    }

    useEffect(() => {
        getRights();
    }, [auth.currentUser?.uid])

    // save and errase user from localstorage
    useEffect(() => {
        //console.log("run user save")
        if (user !== null) {
            localStorage.setItem("user", JSON.stringify(user));
        } else {
            //console.log("removeUser")
            localStorage.removeItem("user");
        }


    }, [user])




    // function for log in and out the user
    const loginUser = async (email: string, password: string): Promise<boolean> => {
        try {
            //console.log(email + " " + password)
            const _user = await signInWithEmailAndPassword(auth, email, password);

            setUser(_user);
            return true;
        } catch (err) {
            //console.error(err);
            return false;
        }
    }

    // 
    const logOutUser = async (): Promise<boolean> => {
        try {
            await signOut(auth);
            setUser(null);
            return true;
        } catch (error) {
            return false;
        }
    }

    const [matches, setMatches] = useState<Match[]>([])

    //get matches
    useEffect( () => {
        //console.log("Content provider match query")

        async function getMatches() {

            let m: Match[] = await GetMatches();

            console.log(m);

            setMatches(prev => m);
        }

        getMatches();

    }, [])

    function giveMatches(): Match[]{
        return matches;
    }

    return (
        <AppContext.Provider value={{ user: user, login: loginUser, logout: logOutUser, matches: matches, getMatches: giveMatches, isUserAdmin: isUserAdmin }}>
            {children}
        </AppContext.Provider>
    )

}

export default AppProvider;