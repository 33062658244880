import { Timestamp, collection, getDocs, query, where } from "firebase/firestore";
import { Bet, Match, PlayerPointCount, PlayerResult, Result } from "./DBModels";
import { GetBet, GetResult } from "./DatabaseHelper";
import { db } from "../config/firebase";

export { }




export async function ResultCounter(): Promise<PlayerPointCount[]> {

    try {
        const finishedMatches = await GetFinishedMatches();

        const users: PlayerPointCount[] = await GetUsers();

        console.log(users)

        for (let i = 0; i < finishedMatches.length; i++) {

            let result = await GetResult(finishedMatches[i].matchID);

            if (result) {

                //go thorugh all of the users and see their bets
                for (let j = 0; j < users.length; j++) {

                    let bet = await GetBet(finishedMatches[i].matchID, users[j].userID)

                    if (bet) {

                        let point = getPoint(bet, result);

                        users[i].point += point;
                    }
                }
            }
        }

        const standings: PlayerResult[] = users.map(u => ({
            nickname: u.nickname,
            point: u.point
        }))

        return users;

    } catch (error) {
        console.log(error)
    }

    return [];
}

export async function GetFinishedMatches(date: Date = new Date()): Promise<Match[]> {

    //return all match which is already finished
    const collectionRef = collection(db, "matches");

    const timeStamp = Timestamp.fromDate(date);

    const eventQuery = query(collectionRef,
        where("start", "<=", timeStamp)
    )

    try {
        const querySnapshot = await getDocs(eventQuery);

        const matches: Match[] = querySnapshot.docs.map(doc => ({
            country1: doc.data().country1,
            country2: doc.data().country2,
            matchID: doc.data().matchID,
            start: doc.data().start,
        }));

        console.log(matches);
        return matches;
    } catch (error) {
        console.log(error)
    }

    return [];
}

export async function GetUsers(): Promise<PlayerPointCount[]> {
    //return all player

    const collectionRef = collection(db, "users");

    try {

        const data = await getDocs(collectionRef);

        const users: PlayerPointCount[] = data.docs.map(doc => ({
            userID: doc.data().userID,
            nickname: doc.data().nickname,
            point: 0,
        }));

        return users;
    } catch (error) {
        console.log(error);
    }

    return [];
}

function getPoint(bet: Bet, result: Result): number {

    let point = 0;
    if (result && bet) {

        let exactRes = bet.result1 === result.result1 && bet.result2 === result.result2;

        let betOutcome = (bet.result1! >= bet.result2!) ? (bet.result1 === bet.result2 ? 0 : 1) : 2; // 0:X , 1: H, 2: G

        let outcome = (result.result1! >= result.result2!) ? (result.result1 === result.result2 ? 0 : 1) : 2;

        let correctOutcame = outcome === betOutcome;


        
        if (exactRes) point = 5;
        else if (correctOutcame) point = 3;

    }
    return point;
}