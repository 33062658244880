import { Box, Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddMatchComponent from "../Componenets/AddMatchComponent";
import MatchComponenet from "../Componenets/Match";
import { Match, PlayerResult } from "../DB/DBModels";
import { useAppContext } from "../Base/ContentProvider";
import StandingComponent from "../Componenets/StandingsComponent";
import { ResultCounter } from "../DB/ResultCounter";
import { LoadingButton } from "@mui/lab";
import { SetStandings } from "../DB/DatabaseHelper";

function AdminPage() {

    const { user, matches, getMatches } = useAppContext();

    const [matchList, setMatchList] = useState<Match[]>([])

    useEffect(() => {

        setMatchList(matches)

    }, [matches])

    const [loading, setLoading] = useState(false)

    const [standings, setStandings] = useState<PlayerResult[]>([])

    async function RenderStanding() {

        setLoading(true)

        const s = await SetStandings();

        
        setLoading(false)
    }

    return (
        <Box sx={{ padding: 2 }}>

            <AddMatchComponent />

            <Box sx={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <Paper sx={{}}>

                    <Box sx={{ width: 500, height: 600, overflow: "auto", gap: 2, display: "flex", flexDirection: "column" }}>
                        {matchList.map((match, index) => (
                            <MatchComponenet key={index} country1={match.country1} country2={match.country2} start={match.start} matchID={match.matchID} addResult={true} />
                        ))}
                    </Box>

                </Paper>

                <Paper>
                        <LoadingButton 
                        variant="contained"
                        loading={loading}
                        loadingPosition="start"
                        onClick={RenderStanding}>Update standings</LoadingButton>
                        <StandingComponent /> 

                </Paper>
            </Box>
        </Box>
    )
}

export default AdminPage;