import React, { ReactNode } from "react";
import Navbar from "./Navbar";
import { Box, Toolbar } from "@mui/material";
import { Toaster } from "react-hot-toast";


interface MainLayoutProps {
    children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {

    return (
        <Box >
            <Navbar />
            <Box>
                <Toolbar />
                {children}
                <Toaster />
            </Box>
        </Box>
    )
}

export default MainLayout