import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import { Match } from "../DB/DBModels";
import { COUNTRIES, GetCodeByName } from "../DB/countries";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Timestamp } from "firebase/firestore";
import { AddMatch } from "../DB/DatabaseHelper";

function AddMatchComponent() {

    const [dialogOpen, setDialogOpen] = useState(false)

    const [country1, setCountry1] = useState<string>()

    const [country2, setCountry2] = useState<string>()

    const [loading, setLoading] = useState(false);

    const [date, setDate] = useState<Dayjs | null>(dayjs('2024-06-18'))

    const [time, setTime] = useState<Dayjs | null>(dayjs())

    async function handleAddNewMatch() {
        setLoading(true);

        const myDate = dayjs(`${date?.year()}-${date?.month()! + 1}-${date?.date()} ${time?.hour()}:${time?.minute()}`);

        const jsDate = myDate.toDate();

        const timeStamp: Timestamp = Timestamp.fromDate(jsDate);

        const matchID = `${GetCodeByName(country1!)}${GetCodeByName(country2!)}${date?.month()! + 1}${date?.date()}`

        const newMatch: Match = { country1: country1!, country2: country2!, matchID: matchID, start: timeStamp };

        //window.alert(JSON.stringify(newMatch))

        await AddMatch(newMatch);

        setLoading(false);
    }

    return (
        <Box>
            <Button variant="outlined" onClick={() => setDialogOpen(true)}>Add new match</Button>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogContent>
                    <Typography>Add new match</Typography>
                    <Box sx={{ display: "flex", gap: 4, flexDirection:"column" }}>
                        <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
                            <FormControl sx={{ width: 200 }}>
                                <InputLabel id="country1">Country1</InputLabel>
                                <Select label="Country1" labelId="country1" value={country1} onChange={(e) => setCountry1(e.target.value)}>
                                    {COUNTRIES.map(c => <MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ width: 200 }}>
                                <InputLabel id="country1">Country2</InputLabel>
                                <Select label="Country1" labelId="country1" value={country2} onChange={(e) => setCountry2(e.target.value)}>
                                    {COUNTRIES.map(c => <MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <Box>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            value={date}
                                            onChange={(newvalue) => setDate(newvalue)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                            <Box>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimePicker', 'TimePicker']}>
                                        <TimePicker
                                            value={time}
                                            onChange={(newValue) => setTime(newValue)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} variant="outlined" onClick={handleAddNewMatch}>Add new match</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default AddMatchComponent;