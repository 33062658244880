import { Box, Typography } from "@mui/material";
import React from "react";
import Login from "../Base/Login";

function LandingPage(){


    return(
        <Box>
           <Box sx={{display: "fex", alignItems: "center", justifyContent: "center", padding: 4}}>
                <Login />
           </Box>
        </Box>
    )
}

export default LandingPage;