import { Box, Fade, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PlayerResult } from "../DB/DBModels";
import { GetStandings } from "../DB/DatabaseHelper";


function StandingComponent() {

    const [standings, setStandings] = useState<PlayerResult[]>()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getStandings() {
            const s = await GetStandings();

            setStandings(s);
            setLoading(false)
        }

        getStandings();

    }, [])

    return (
        <Fade in={!loading} timeout={1000}>
            <Box>
                {standings?.map((player, index) => (
                    <Paper key={index} sx={{ width: 200 }}>
                        <Typography>{player.nickname}</Typography>
                        <Typography>{player.point}</Typography>
                    </Paper>
                ))}
            </Box>
        </Fade>
    )
}

export default StandingComponent;