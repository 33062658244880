import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppContext } from "../Base/ContentProvider";
import MatchComponenet from "../Componenets/Match";
import { Match } from "../DB/DBModels";
import AddMatchComponent from "../Componenets/AddMatchComponent";

function HomePage() {

    const { user, matches, getMatches } = useAppContext();

    const formattedDate = (new Date()).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
    });


    const [matchList, setMatchList] = useState<Match[]>([])

    useEffect(()=> {

        setMatchList(matches)

    }, [matches])

    function openMatch(matchID: string) {
        window.alert(matchID)
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", padding: 4, gap: 2 }}>
            <Box>
                <Typography variant="h3">{formattedDate}</Typography>
            </Box>

            {/* <Button onClick={() => {
                window.alert(JSON.stringify(matchList))
                window.alert(JSON.stringify(getMatches()))
            }}>Test</Button>

            <AddMatchComponent /> */}

            <Box sx={{}}>

                <Box sx={{ width: 500, height: 600, overflow: "auto", gap: 2, display: "flex", flexDirection: "column" }}>
                    {matchList.map((match, index) => (
                        <MatchComponenet key={index} country1={match.country1} country2={match.country2} start={match.start} matchID={match.matchID}/>
                    ))}
                </Box>

            </Box>

        </Box>
    )
}

export default HomePage;