import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserData } from "../DB/DBModels";
import { GetUserData, SetUserData } from "../DB/DatabaseHelper";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import toast, { Toaster } from "react-hot-toast";
import { auth } from "../config/firebase";
import { useAppContext } from "../Base/ContentProvider";
import { AuthCredential, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";

function ProfilPage() {

    const { user } = useAppContext();

    const notify = (msg: string) => toast(msg);

    const [userData, setUserData] = useState<UserData>({ userID: "", nickname: "" })

    useEffect(() => {

        async function getUserData() {
            const uData = await GetUserData(auth.currentUser?.uid);

            if (uData) {
                setUserData(uData);
            }
        }

        getUserData();

    }, [auth.currentUser?.uid])

    const [loading, setLoading] = useState(false)

    async function saveUserData() {

        setLoading(true)

        let result = await SetUserData(userData);

        notify(result ? "User data saved!" : "Error happend during saving!");

        setLoading(false)
    }


    const [oldPassword, setOldPassword] = useState<string>();

    const [newPassword, setnewPassword] = useState<string>();

    const [confirmNewPassword, setconfirmNewPassword] = useState<string>();

    const [differentPasswords, setDifferentPasswords] = useState(false);

    useEffect(() => {

        function comparePasswords() {

            if (!newPassword || !confirmNewPassword) {

                return;
            }
            if (newPassword !== confirmNewPassword) {
                setDifferentPasswords(true);
                return;
            }
            setDifferentPasswords(false);
        }

        comparePasswords();

    }, [newPassword, confirmNewPassword])

    async function changePassword(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        try {
            const user = auth.currentUser;
            // const credential = auth.EmailAuthProvider.credential

            const credential = EmailAuthProvider.credential(user?.email!, oldPassword!)
            await reauthenticateWithCredential(user!, credential);
            await updatePassword(user!, newPassword!);
            toast.success("Password changed")
            return;

        }
        catch(error){
            console.log(error);
        }

        toast.error("Ohh no")
        
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4, justifyContent: "center", alignItems: "center", padding: 2, }}>
            <Paper sx={{ width: "80%", display: "flex", flexDirection: "column", gap: 2, padding: 2, alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <Typography>Nickname:</Typography>
                    <TextField value={userData?.nickname} onChange={(e) => setUserData({ ...userData, nickname: e.target.value })} />
                </Box>

                <Box >
                    <LoadingButton
                        onClick={saveUserData}
                        variant="contained"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<Save />}
                    >Save modifications</LoadingButton>
                </Box>


            </Paper>
            <Paper sx={{ width: "80%", display: "flex", flexDirection: "column", gap: 2, padding: 2, alignItems: "center" }}>
                <Typography>Change password</Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <form style={{ display: "flex", flexDirection: "column", gap: 14 }}
                        onSubmit={changePassword}
                    >
                        <TextField required label="Old password" type="password" onChange={(e) => setOldPassword(e.target.value)}
                            value={oldPassword}
                        />
                        <TextField required label="New password" type="password" onChange={(e) => setnewPassword(e.target.value)}
                            value={newPassword}
                        />
                        <TextField required label="Confirm new password" type="password" onChange={(e) => setconfirmNewPassword(e.target.value)}
                            error={differentPasswords}
                            value={confirmNewPassword}
                        />
                        <Button type="submit" disabled={differentPasswords} variant="outlined" >Change</Button>
                    </form>
                </Box>
            </Paper>
        </Box>
    )
}

export default ProfilPage;