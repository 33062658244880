import React, { useEffect, useState } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { AccountCircle, LinearScale, SportsSoccer } from "@mui/icons-material";
import { useAppContext } from "./ContentProvider";
import { auth } from "../config/firebase";

function Navbar() {

    const { user, logout, isUserAdmin } = useAppContext()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const navigate = useNavigate();

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = async () => {

        let result = await logout();


        console.log("logged out :" + user)

        setAnchorEl(null);
    };


    return (
        <AppBar  sx={{}}>
            <Toolbar>
                <IconButton href="/home">
                    <SportsSoccer />
                </IconButton>
                <Typography sx={{ flexGrow: 1 }}></Typography>
                
                {isUserAdmin && <Button variant="contained" color="info" sx={{marginRight: 2}} href="/admin">AdminPanel</Button>}
                <Typography sx={{marginRight: 4}}>{auth.currentUser?.email}</Typography>
                {user ?
                    <IconButton edge="end"
                        sx={{padding: 0}}
                        onClick={handleMenuOpen}
                    >
                        <AccountCircle />
                    </IconButton> :
                    <Button
                    variant="text"
                    sx={{color: "black"}}
                    href="/login"
                    >Login</Button>
                }
            </Toolbar>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                sx={{ marginTop: "35px" }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
                {/* <MenuItem >My account</MenuItem> */}
                <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
            </Menu>
        </AppBar>
    )
}

export default Navbar