import { Box, Button, Card, CardActionArea, CardContent, Dialog, DialogActions, DialogContent, Grid, Paper, TextField, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { Bet, Result } from "../DB/DBModels";
import { AddBet, AddResult, GetBet, GetResult } from "../DB/DatabaseHelper";
import { auth } from "../config/firebase";
import { GetCodeByName } from "../DB/countries";


interface Props {
    country1: string,
    country2: string,
    matchID: string,
    start: Timestamp,
    addResult?: boolean,
}

function MatchComponenet(props: Props) {

    const date = props.start.toDate();

    const now = new Date();
    const enabled = date > now;

    const formattedDate = `${date.getMonth() + 1}.${date.getDate()}`

    const [dialogOpen, setDialogOpen] = useState(false)

    const [result, setResult] = useState<Result>()
    const [bet, setBet] = useState<Bet>({matchID: "", userID: ""})
    useEffect(() => {

        async function getResult() {

            let res = await GetResult(props.matchID);

            if (res) {
                setResult(res);
            }

        }

        async function getBet() {
            let bet = await GetBet(props.matchID, auth.currentUser?.uid!)

            if (bet) {
                setBet(bet);
            }
        }

        getResult();
        if(!props.addResult){
            getBet();
        }

    }, [])

    const [loading, setLoading] = useState(false);

    async function saveBet() {
        setLoading(true);
        await AddBet(props.matchID, auth.currentUser?.uid!, bet!)

        setLoading(false);
    }

    async function saveResult() {
        setLoading(true);

        const result: Result = { result1: bet?.result1!, result2: bet?.result2!, matchID: props.matchID }

        await AddResult(result);

        setLoading(false);
    }

    return (
        <Paper sx={{ width: 360, }}>
            <Card sx={{ backgroundColor: enabled ? "white" : "lightgray" }}>
                <CardActionArea
                    onClick={() => {
                        if (enabled) { setDialogOpen(true) }
                    }}
                >
                    <CardContent>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                {/* Left Column */}
                                <Grid item xs={4}>
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                                        <ReactCountryFlag countryCode={GetCodeByName(props.country1)} svg style={{
                                            fontSize: '2em',
                                            lineHeight: '2em',

                                        }} />
                                        <Typography variant="h6" align="center">{props.country1}</Typography>
                                    </Box>
                                </Grid>

                                {/* Middle Column */}
                                <Grid item xs={4}>
                                    <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                        {/* Top Row */}
                                        <Box sx={{ display: "flex" }}>
                                            {!result && <Typography variant="h6" align="center" sx={{color: "red"}}>{bet?.result1}</Typography>}
                                            <Typography variant="h6" align="center">{result?.result1}</Typography>
                                            <Typography variant="h6" align="center">:</Typography>
                                            <Typography variant="h6" align="center">{result?.result2}</Typography>
                                            {!result && <Typography variant="h6" align="center" sx={{color: "red"}}>{bet?.result2}</Typography>}
                                        </Box>
                                        {/* Bottom Row */}
                                        <Box>
                                            <Typography variant="h6" align="center">{formattedDate}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                {/* Right Column */}
                                <Grid item xs={4}>
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                                        <ReactCountryFlag countryCode={GetCodeByName(props.country2)} svg style={{
                                            fontSize: '2em',
                                            lineHeight: '2em',

                                        }} />
                                        <Typography variant="h6" align="center">{props.country2}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                    </CardContent>
                </CardActionArea>
            </Card>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Typography>{formattedDate}</Typography>
                        <Typography>{props.addResult ? "Add result" : "Add bet"}</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <TextField label={props.country1} value={bet?.result1} onChange={(e) => setBet({ ...bet, result1: parseInt(e.target.value) })}></TextField>
                            <TextField label={props.country2} value={bet?.result2} onChange={(e) => setBet({ ...bet, result2: parseInt(e.target.value) })}></TextField>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {props.addResult ?
                        <Button disabled={loading} variant="outlined" onClick={saveResult}>Add result</Button> :
                        <Button disabled={loading} variant="outlined" onClick={saveBet}>Save my bet</Button>}
                </DialogActions>
            </Dialog>
        </Paper>
    )

}

export default MatchComponenet