import { Timestamp, collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { auth, db } from "../config/firebase";
import { Bet, Match, PlayerResult, Result, UserData } from "./DBModels";
import { GetUsers, ResultCounter } from "./ResultCounter";



export async function GetMatches(): Promise<Match[]> {

    const collectionRef = collection(db, "matches");

    try {

        const data = await getDocs(collectionRef);

        const Matches: Match[] = data.docs.map(doc => ({
            matchID: doc.data().matchID,
            country1: doc.data().country1,
            country2: doc.data().country2,
            start: doc.data().start
        }));

        return Matches;

    } catch (error) {
        console.log(error);
        return [];
    }

}

export async function GetResult(matchID: string): Promise<Result | null> {

    const docRef = doc(db, "results", matchID);

    try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const result: Result = { result1: docSnap.data().result1, result2: docSnap.data().result2, matchID: docSnap.data().matchID }

            return result;
        }
    } catch (error) {
        console.log(error);
    }

    return null;
}

export async function OldGetResult(matchID: string): Promise<Result | null> {

    const collectionRef = collection(db, "results");

    const eventQuery = query(collectionRef,
        where("matchID", "==", matchID)
    );

    try {
        const querySnapshot = await getDocs(eventQuery);

        if (querySnapshot.size == 1) {

            const Data: Result[] = querySnapshot.docs.map(doc => ({
                result1: doc.data().result1,
                result2: doc.data().result2,
                matchID: doc.data().matchID
            }))

            console.log(Data[0])
            return Data[0];
        }
    } catch (error) {
        console.log(error)
    }

    return null;

}


export async function GetBet(matchID: string, userID: string): Promise<Bet | null> {

    const docRef = doc(db, "bets", userID, "userBets", matchID)

    try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let bet: Bet = { result1: docSnap.data()!.result1, result2: docSnap.data().result2, matchID: docSnap.data().matchID, userID: docSnap.data().userID }

            return bet;
        }
    } catch (error) {
        console.log(error)
    }
    return null;
}

export async function OldGetBet(matchID: string, userID: string): Promise<Bet | null> {

    const collectionRef = collection(db, "bets");

    const eventQuery = query(collectionRef,
        where("matchID", "==", matchID),
        where("userID", "==", userID)
    )

    try {
        const querySnapshot = await getDocs(eventQuery);

        if (querySnapshot.size == 1) {
            const Data: Bet[] = querySnapshot.docs.map(doc => ({
                result1: doc.data().result1,
                result2: doc.data().result2,
                userID: doc.data().userID,
                matchID: doc.data().matchID
            }))

            return Data[0];
        }
    } catch (error) {
        console.log(error)
    }

    return null;
}

export async function AddBet(matchID: string, userID: string, bet: Bet): Promise<boolean> {

    //const collectionRef = doc(db, "bets", `${matchID}_${userID}`);

    const collectionRef = doc(db, "bets", userID, "userBets", matchID);

    try {

        await setDoc(collectionRef, {
            result1: bet.result1,
            result2: bet.result2,
            userID: userID,
            matchID: matchID
        })
        return true;

    } catch (error) {
        console.log(error)
    }

    return false;

}

export async function AddMatch(match: Match): Promise<boolean> {

    const collectionRef = doc(db, "matches", match.matchID)

    try {

        await setDoc(collectionRef, {
            matchID: match.matchID,
            start: match.start,
            country1: match.country1,
            country2: match.country2
        });
        return true;

    } catch (error) {
        console.log(error)
    }

    return false;
}

export async function AddResult(result: Result): Promise<boolean> {

    const docRef = doc(db, "results", result.matchID);

    try {

        await setDoc(docRef, result);
        return true;

    } catch (error) {
        console.log(error)
    }

    return false;
}

export async function IsUserAdmin(userID: string | undefined): Promise<boolean> {

    if (!userID) return false;

    const docRef = doc(db, "userRights", userID);

    try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            const isAdmin: boolean = docSnap.data().admin;
            return isAdmin;
        }
    } catch (error) {
        console.log(error)
    }

    return false;
}

export async function GetUserData(userID: string | undefined): Promise<UserData | null> {

    if (!userID) return null;

    const docRef = doc(db, "users", userID);

    try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const userData: UserData = { nickname: docSnap.data().nickname, userID: docSnap.data().userID }

            return userData;
        }
    } catch (error) {
        console.log(error);
    }

    return null;
}

export async function SetUserData(userData: UserData | undefined): Promise<boolean> {

    if (!userData) return false;

    if (!auth.currentUser?.uid) return false;

    try {

        const docRef = doc(db, "users", auth.currentUser?.uid || "");

        const userD = userData;
        userD.userID = auth.currentUser?.uid
        await setDoc(docRef, userD);

        return true;

    } catch (error) {
        console.log(error)
    }

    return false;
}


export async function GetStandings(): Promise<PlayerResult[]> {

    try {

        const collectionRef = collection(db, "standings");

        const data = await getDocs(collectionRef);

        const standings: PlayerResult[] = data.docs.map(doc => ({
            nickname: doc.data().nickname,
            point: doc.data().point,
        }));

        return standings;

    } catch (error) {
        console.log(error)
    }


    return [];
}

export async function SetStandings(): Promise<boolean> {

    try {
        const users = await GetUsers();

        const standings = await ResultCounter();

        for (let i = 0; i < standings.length; i++){
            const docRef = doc(db, "standings", standings[i].userID);

            await setDoc(docRef, standings[i]);
        }

    } catch (error) {
        console.log(error)
    }

    return false;
}